import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/shared/Container"
import FullSlider from "../components/FullSlider"

const IndexPage = ({data}) => {
  const allWorks = data.allContentfulWorks.edges

  return (
    <Layout>
      <SEO title="YUSUKE OIKE" />
      <Container>
        <FullSlider works={allWorks} />
      </Container>
    </Layout>
  )
}


export const query = graphql`
  query {
    allContentfulWorks(sort: {fields: year, order: DESC}) {
        edges {
          node {
            title
            client
            slug
            year(formatString: "YYYY")
            roles
            color
            keyImage {
              resize(width: 1600) {
                src
              }
            }
          }
        }
      }
    }
`

export default IndexPage
