import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import MyIcon from "../MyIcon"
import { device } from "../../utils/device"

const SlideNavigation = ({ works, setActiveIndex, activeIndex, color }) => {
  return (
    <SlideNavigationWrapper>
      <IconWrapper>
        <MyIcon keyColor={color} />
      </IconWrapper>
      <nav>
        <ul
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {works.map((work, index) => {
            const isActive = index === activeIndex

            return (
              <StyledNavigationItem
                index={index}
                activeIndex={activeIndex}
                work={work.node}
                onMouseEnter={() => setActiveIndex(index)}
                isActive={isActive}
                key={`${work.node.title}-${index}`}
              >
                <Link to={`/works/${work.node.slug}`}>
                  {work.node.title}
                  <span>{work.node.roles && work.node.roles.join()}</span>
                  <span>{new Date(work.node.year).getFullYear()}</span>
                </Link>
              </StyledNavigationItem>
            )
          })}
        </ul>
      </nav>
    </SlideNavigationWrapper>
  )
}

const SlideNavigationWrapper = styled.div`
  display: block;
  width: 30%;

  @media ${device.mobile} {
    display: none;
  }
`

const IconWrapper = styled.div`
  display: block;
  width: 128px;
  margin-bottom: 48px;
  transform: translateX(-4px);
`

const StyledNavigationItem = styled.li`
  padding-top: 8px;
  padding-bottom: 12px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  a {
    position: relative;
    text-decoration: none;
    color: ${props =>
      props.isActive
        ? props.theme.colors.baseFontColor
        : props.theme.colors.baseGrayColor};
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -4px;
      background-color: ${props => props.work.color};
      transition: transform 0.4s ease-in-out;
      transform: scaleX(${props => (props.isActive ? "1" : "0")});
      transform-origin: left;
    }
    span {
      margin-left: 8px;
      font-size: 0.7em;
      text-transform: uppercase;
      opacity: ${props => (props.isActive ? "1" : "0")};
      transition: opacity 0.4s 0.1s ease;
    }
  }
`

export default SlideNavigation
