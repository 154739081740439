import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { device } from "../../utils/device"

const SlideImage = ({index, activeIndex, work}) => {
  return (
    <SlideImageWrapper index={index} image={work.node.keyImage.resize.src} activeIndex={activeIndex}>
      <SlideImageCredit color={work.node.color}>
        <Link to={`/works/${work.node.slug}`}>
          { work.node.title }
        </Link>
        <div>
          <span>
            { work.node.roles.join() }
          </span>
          <span>
            { work.node.year }
          </span>
        </div>
      </SlideImageCredit>
    </SlideImageWrapper>
  )
}

const SlideImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  visibility: ${props => props.index === props.activeIndex ? 'visible' : 'hidden'};
  transition: transform 0.5s ease-in-out;
  transform: translateX(${props => props.index === props.activeIndex ? '0' : '-100%'});

  @media ${device.mobile} {
    position: relative;
    visibility: visible;
    transform: translateX(0);
    transition: none;
    min-height: 280px;
    margin-bottom: 72px;
    box-shadow: 3px 7px 16px -6px rgba(0,0,0,0.07), 15px 20px 37px -2px rgba(0,0,0,0.07), 20px 42px 80px 6px rgba(0,0,0,0.07), 20px 42px 120px 10px rgba(0,0,0,0.07);
  }
`
const SlideImageCredit = styled.div`
  display: none;

  @media ${device.mobile} {
    font-size: 12px;
    position: absolute;
    display: block;
    padding-top: 16px;
    padding-bottom: 8px;
    width: 100%;
    bottom: -52px;
    left: 0;
    color: ${props => props.theme.colors.baseFontColor};
    a {
      position: relative;
      display: inline-block;
      color: inherit;
      font-weight: 500;
      text-decoration: none;
      margin-bottom: 8px;
      &::after {
        position: absolute;
        bottom: -4px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${props => props.color};
      }
    }
    span {
      font-weight: 400;
      font-size: 10px;
      text-transform: uppercase;
      margin-right: 8px;
      color: ${props => props.theme.colors.baseGrayColor};
    }
  }
`
export default SlideImage;
