import React from "react"
import styled from "@emotion/styled"
import SlideImage from "./SlideImage"
import { device } from "../../utils/device"

const SlideContents = ({works, activeIndex}) => {
  return (
    <StyledSlideContent>
      {
        works.map((work, index) => (
          <SlideImage key={`${work.title}-${index}`} index={index} activeIndex={activeIndex} work={work} />
        ))
      }
    </StyledSlideContent>
  )
}

const StyledSlideContent = styled.div`
  position:relative;
  display: block;
  overflow: hidden;
  min-height: calc(100vh - 192px);
  width: 100%;
  box-shadow: 3px 7px 16px -6px rgba(0,0,0,0.07), 15px 20px 37px -2px rgba(0,0,0,0.07), 20px 42px 80px 6px rgba(0,0,0,0.07), 20px 42px 120px 10px rgba(0,0,0,0.07);

  @media ${device.mobile} {
    box-shadow: none;
    overflow: visible;
    display: flex;
    flex-direction: column;
  }
`

export default SlideContents