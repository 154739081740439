import React, { useState } from "react"
import styled from "@emotion/styled"
import SlideNavigation from "./SlideNavigation"
import SlideContents from "./SlideContents"
import SlideColor from "./SlideColor";

const FullSlider = ({works}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const color = works[activeIndex].node.color

  return (
    <>
    <StyledFullSlider>
      <SlideNavigation works={works} setActiveIndex={setActiveIndex} activeIndex={activeIndex} color={color} />
      <SlideContents works={works} activeIndex={activeIndex} />
    </StyledFullSlider>
    <SlideColor color={color} />
    </>
  )
}

const StyledFullSlider = styled.div`
  display: flex;
  position: relative;
`

export default FullSlider
