import React from 'react'
import styled from "@emotion/styled"
import { device } from "../../utils/device"

const SlideColor = ({color}) => {
  return (
    <StyledSlideColor color={color} />
  )
}

const StyledSlideColor = styled.div`
  position: fixed;
  z-index: -2;
  width: 30%;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: ${props => props.color};
  transition: background-color 0.4s 0.2s linear;

  @media ${device.mobile} {
    display: none;
  }
`

export default SlideColor